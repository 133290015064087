import React, { Component } from 'react'
import logoDark from '../../assets/images/hardkoder_dark.svg'

class Header extends Component {

    render() {
        return(
            <header id="header" className="header fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <a href="/" className="logo d-flex align-items-center">
                        <img src={logoDark} alt="logo dark"/>
                    </a>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="btn-navbar-contact scrollto" href="#contact">Contact Us</a></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                </div>
            </header>
        )
    }

}

export default Header
import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import aws from '../../assets/images/aws_certified.png'
import shopify from '../../assets/images/shopify_partners.png'

class HomeSectionClients extends Component {
    render() {
        return (
            <section id="clients" className="clients">
                <Container data-aos="fade-up">
                    <Row>
                        <Col lg="2" mt="1" mt-lg="0"></Col>
                        <Col lg="4" mt="4" mt-lg="0" className="align-self-center">
                            <div className="client-container text-center" data-aos="fade-up" data-aos-delay="600">
                                <img src={aws} className="img-fluid" alt=""/>
                            </div>
                        </Col>
                        <Col lg="4" mt="4" mt-lg="0" className="align-self-center">
                            <div className="client-container text-center" data-aos="fade-up" data-aos-delay="600">
                                <img src={shopify} className="img-fluid" alt=""/>
                            </div>
                        </Col>
                        <Col lg="2" mt="1" mt-lg="0"></Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default HomeSectionClients
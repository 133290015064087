import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import contactLogo from '../../assets/images/contact.svg'
import { ReactTypeformEmbed } from 'react-typeform-embed';


class HomeSectionContact extends Component {

    constructor(props) {
        super(props);
        this.openForm = this.openForm.bind(this);
    }

    openForm() {
        this.typeformEmbed.typeform.open();
    }

    render() {
        return (
            <section className="contact" id="contact">
                <Container data-aos="fade-up">
                    <Row>
                        <Col lg='3'>
                            <img src={contactLogo} className="img-fluid" alt=""/>
                        </Col>

                        <Col lg='9' mt='9' mt-lg='0' className="align-self-center">
                            <h2>Need free consultation? Hit us up! <span role="img" aria-label="wave">👋</span></h2>
                            <ReactTypeformEmbed
                                popup
                                autoOpen={false}
                                url="https://kevinanggara.typeform.com/to/lwRhC5"
                                hideHeaders
                                hideFooter
                                buttonText="Go!"
                                style={{ top: 200 }}
                                ref={tf => {
                                    this.typeformEmbed = tf;
                                }}
                            />
                            <button onClick={this.openForm} style={{ cursor: 'pointer' }} className="btn btn-contact-us">
                                Contact Us
                            </button>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default HomeSectionContact
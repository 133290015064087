import React, { Component } from 'react'
import { Row, Container } from 'react-bootstrap'
import heroRocket from '../../assets/images/hero.svg'

class HomeSectionHero extends Component {
    render() {
        return (
            <section id="hero" className="hero d-flex align-items-center">
                <Container>
                    <Row>
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                            <h1 data-aos="fade-up">Crafting solution with style <span role="img" aria-label="sunglasses">😎</span></h1>
                            <p data-aos="fade-up" data-aos-delay="400">We are bunch of committed practicioners in making your dream become reality</p>
                            <div data-aos="fade-up" data-aos-delay="600">
                                <a href="#contact" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Request a project</span>
                                <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
                            <img src={heroRocket} className="img-hero" alt=""/>
                        </div>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default HomeSectionHero
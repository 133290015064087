import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import dciWork from '../../assets/images/works-dci.png'
import bbWork from '../../assets/images/works-bb.png'
import gaweanWork from '../../assets/images/works-gawean.png'
import belamyWork from '../../assets/images/works-belamy.png'

class HomeSectionWorks extends Component {
    render() {
        return (
            <section id="works" className="works">
                <Container data-aos="fade-up">

                    <header className="section-header center">
                        <h2>Our Works</h2>
                    </header>
                    <Row>
                        <Col lg="6">
                            <div className="card" data-aos="fade-up" data-aos-delay="200">
                                <a href="https://dci-indonesia.com" target='_blank' rel="noreferrer">
                                    <img src={dciWork} className="img-fluid" alt=""/>
                                    <h3>DCI Indonesia</h3>
                                    <p>Company Profile Website</p>
                                </a>
                            </div>
                        </Col>

                        <Col lg="6" mt="6" mt-lg="0">
                            <div className="card" data-aos="fade-up" data-aos-delay="400">
                                <a href="https://gawean.id" target='_blank' rel="noreferrer">
                                    <img src={gaweanWork} className="img-fluid" alt=""/>
                                    <h3>Gawean</h3>
                                    <p>Marketplace Platform</p>
                                </a>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" mt="6" mt-lg="0">
                            <div className="card" data-aos="fade-up" data-aos-delay="200">
                                <a href="https://beningbersinar.com" target='_blank' rel="noreferrer">
                                    <img src={bbWork} className="img-fluid" alt=""/>
                                    <h3>Bening Bersinar</h3>
                                    <p>Online Shop, SEO</p>
                                </a>
                            </div>
                        </Col>

                        <Col lg="6" mt="6" mt-lg="0">
                            <div className="card" data-aos="fade-up" data-aos-delay="400">
                                <a href="https://belamystore.com" target='_blank' rel="noreferrer">
                                    <img src={belamyWork} className="img-fluid" alt=""/>
                                    <h3>Belamy</h3>
                                    <p>Online Shop</p>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default HomeSectionWorks
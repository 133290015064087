import React from "react"
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import Header from './common/Header';
import Footer from './common/Footer';

export default function Layout({ children }) {
  return (
    <>
        <Header/>
        {children}
        <Footer/>
    </>
  )
}
import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import logoLight from '../../assets/images/hardkoder.svg'

class Footer extends Component {
    
    render() {
        return(
            <footer className="footer">
                <Container className="footer-container footer-top">
                    <Row>
                        <Col lg="6" xs="12" className="mt-6 mt-lg-0 align-self-center">
                            <img src={logoLight}  alt="logo light" />
                            <p className="footer-note">
                            Hardkoder is a tech creative agency based in Jakarta, Indonesia. We solve problems with <span role="img" aria-label="love">❤</span>
                            </p>
                        </Col>
                        <Col lg="6" xs="12" className="mt-6 mt-lg-0 align-self-center">
                            <p className="footer-contact-data">contact@hardkoder.id<br/>Instagram: @hardkoder.id</p>
                        </Col>
                    </Row>
                    
                </Container>
                <Container>
                    <Row>
                        <Col lg="12" className="copyright">
                            <p>©2021 Hardkoder - PT. Catur Aruna Teknologi</p>
                            <p>
                                Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default Footer
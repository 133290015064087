import React, { Component } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import technologyIcon from '../../assets/images/technology.png'
import creativeIcon from '../../assets/images/creative.png'
import strategyIcon from '../../assets/images/strategy.png'

class HomeSectionServices extends Component {
    render() {
        return (
            <section id="values" className="values">
                <Container data-aos="fade-up">
                    <header className="section-header">
                        <h2>We offer you...</h2>
                    </header>

                    <Row>
                        <Col lg="4" className="box-container">
                            <div className="box" data-aos="fade-up" data-aos-delay="200">
                                <img src={technologyIcon} className="img-fluid" alt=""/>
                                <h3>Technology</h3>
                                <p>Reliable digital product to align with your needs</p>
                            </div>
                        </Col>

                        <Col lg="4" className="box-container mt-4 mt-lg-0">
                            <div className="box" data-aos="fade-up" data-aos-delay="400">
                                <img src={creativeIcon} className="img-fluid" alt=""/>
                                <h3>Creativity</h3>
                                <p>Innovative solution with engaging design</p>
                            </div>
                        </Col>

                        <Col lg="4" className="box-container mt-4 mt-lg-0">
                            <div className="box" data-aos="fade-up" data-aos-delay="600">
                                <img src={strategyIcon} className="img-fluid" alt=""/>
                                <h3>Strategy</h3>
                                <p>Helping you to know more about your customer</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}

export default HomeSectionServices
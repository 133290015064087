import * as React from "react"
import Layout from '../components/layout'
import HomeSectionHero from '../components/home/HomeSectionHero'
import HomeSectionServices from '../components/home/HomeSectionServices'
import HomeSectionClients from '../components/home/HomeSectionClients'
import HomeSectionWorks from '../components/home/HomeSectionWorks'
import HomeSectionContact from '../components/home/HomeSectionContact'
import SEO from "../components/seo"


// markup
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Hit us up!" />
      <HomeSectionHero />
      <HomeSectionServices />
      <HomeSectionClients />
      <HomeSectionWorks />
      <HomeSectionContact />
    </Layout>
  )
}

export default IndexPage
